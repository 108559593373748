* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

video {
  max-width: 100%;
  height: auto;
}
p{
  text-align: justify;
}

.banner_img{
  background-image: url(../src/assets/background/back1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

@media (min-aspect-ratio:16/9) {
    video{
      width: 100%;
      height: auto;
    }
}
@media (max-aspect-ratio:16/9) {
  video{
    width: auto;
    height: 100%;
  }
}